import React, { useEffect, useReducer, useState } from 'react';
import ReactDom from 'react-dom';

import Navbar from "./navbar.js"
import Footer from "./footer.js"
import DynamicFace from "./DynamicFace.js"

import "./index.css";
import "./about-me.css";
import ParticleBackground from './particleBackground.js';

const AboutMeBody = () => {


    return (
        <>

            <div className="AboutMeContainer"  >
                <center>

                    <div className="AboutMeTopSection">
                        <div className="dynamicFaceContainer">

                            <DynamicFace />
                        </div>

                        <h4 style={{ color: "white" }}>Hi, I'm Jack Freeth</h4>
                        <h2 style={{ color: "white" }}>I'm an aspiring Software Engineer.</h2>
                    </div>
                </center>
                <div className="AboutMeBottomSection" style={{ marginBottom: "300px" }}>
                    <h2 style={{ fontWeight: "bold", padding: "12px", width: "fit-content" }}>Hey,<hr /></h2>
                    <h2 style={{ padding: "12px", width: "fit-content" }}>I'm Jack, a 5th year Software Engineering student at the University of Auckland.<hr /></h2>
                    <h2 style={{ padding: "12px", width: "fit-content" }}>I also study pure mathematics, physics, and have a keen interest in raytracing engines!<hr /></h2>

                </div>
            </div>

        </>
    );
}

/*<h2 style={{padding:"12px", width:"fit-content"}}>Please look around at some of my projects and my better portfolio, and get in touch!<hr/></h2>*/

const AboutMe = () => {

    return (
        <>
            <Navbar />
            <AboutMeBody />
            <Footer />
        </>
    );
}

export default AboutMe;