import React, { useEffect, useReducer, useState } from 'react';
import ReactDom from 'react-dom';

import Navbar from "./navbar.js"
import Footer from "./footer.js"

import "./index.css";
import "./accolades.css";

const AccoladesBody = () => {

    return (
        <div style={{ minHeight: "100%" }}>
            <div className="accoladesTitle">
                <center>
                    <h4>Accolades<hr width="100%" /></h4>

                </center>
            </div>
            <div className="AccoladesBody">
                <div style={{ padding: "20px" }}>
                    <h4>Experience:</h4>
                    <h2><strong>Optiver - Software Engineering Intern. - 2024/2025</strong></h2><br />
                    <h2>Worked as a software engineering intern on the application of C++ to a systematic trading system, and created a machine learning pipeline for use in research.</h2><br />
                    <h2><strong>Lightspeed Studios - C++ Software Engineering Intern. - 2023/2024</strong></h2><br />
                    <h2>Worked as a game client engineering intern, working in a team and developing an Unreal Engine plugin for a tool used to optimise game assets.</h2><br />
                    <h2><strong>Windcave - C++ Software Engineering Intern. - 2022/2023</strong></h2><br />
                    <h2>Worked on a project about developing a simulator for simulating ecommerce transactions using the 3DSecure protocol. This involved creating a C++ backend as well as working amongst the front end to assist in the usage of my API.</h2>
                </div>
                <hr />
                <div style={{ padding: "20px" }}>
                    <h4>Academic:</h4>
                    <h2>J.C. Butcher Award - Annual excellence award in stage 3 Theoretical Computer Science. (Top in CS320 Applied Algorithmics + CS350 Mathematical Foundations) - University of Auckland - 2023</h2><br />
                    <h2>Dux Litterarum - Hobsonville Point Secondary School - 2020</h2><br />
                    <h2>Deans List GPA 8.69 - University of Auckland - 2021-2024</h2><br />
                    <h2>First place in Performance - Parallelised Task Scheduler SOFTENG306 (2023)</h2>
                </div>
                <hr />
                <div style={{ padding: "20px" }}>
                    <h4>Personal:</h4>
                    <h2>Desmos Art Competition Finalist 17-18 (Raycasting Engine) - 2021</h2><br />
                    <h2>Speedcubing 3x3 19 seconds - 2024</h2><br />
                    <h2>Reached #1 in New Zealand for Clash of Clans - 2019,2020,2024</h2>
                </div>
                <hr />
                <div style={{ padding: "20px" }}>
                    <h4>Skills:</h4>
                    <h2><strong>C C++</strong> Java Javascript PHP SQL Python React Desmos CUDA SYCL</h2>
                </div>
                <hr />
                <div style={{ padding: "20px" }}>
                    <h4>Raycasting Engines:</h4>
                    <h2>C C++ SYCL CUDA Javascript Desmos Python Scratch</h2>
                </div>
                <hr />
                <div style={{ padding: "20px" }}>
                    <h4>General interests:</h4>
                    <h2>Pure Mathematics, Physics, C++ development, Raytracing, Maching Learning, Shader Programming/High Throughput Computing, Chess, Speedcubing, Puzzles</h2><br />
                </div>
            </div>
        </div >
    );
}

const Accolades = () => {

    return (
        <>
            <Navbar />
            <AccoladesBody />
            <Footer />
        </>
    );
}

export default Accolades;